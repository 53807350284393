/* eslint-disable */
export const ProductType = 'RPDPLACEHOLDER';

export enum ProductPermissions {
  PLACEHOLDER = 'PLACEHOLDER',
}



export const ReportParameterMappings: any = {

  dailyReportParameterMapping: {
    'EVP': 'UserPrivilegeEVP',
    'SVP': 'UserPrivilegeSVP',
    'REGION': 'UserPrivilegeREGION',
    'MARKET': 'UserPrivilegeMARKET',
    'STORE_NUMBER': 'StoreQuerySTOREID',
    'TodayDefault': 'CalendarDate'
  },
  dapReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'TodayDefault': 'CalendarDate'
  },
  inventoryRecapReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'TodayDefault': 'CalendarDate'
  },
  itemBeingServiceReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'TodayDefault': 'CalendarDate'
  },
  salesPersonReportParameterMapping: {
    'EVP': 'UserPrivilegeEVP',
    'SVP': 'UserPrivilegeSVP',
    'REGION': 'UserPrivilegeREGION',
    'MARKET': 'UserPrivilegeMARKET',
    'STORE_NUMBER': 'SalesPersonRecapSTORENUMBER',
    'TodayDefault': 'CalendarDate'
  },
  skipStolenReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'TodayDefault': 'CalendarDate'
  },

  exceptionAuditTrailReportParameterMapping: {
    'EVP': 'EVP',
    'SVP': 'SVP',
    'REGION': 'Region',
    'MARKET': 'District',
    'STORE_NUMBER': 'StoreNumber',
    'TodayDefault': 'CalendarDate'
  },
}

